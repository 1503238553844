.SayHelloCards {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background: #636ef6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: #e9eafe;
    margin-bottom: 15px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:start {
    background: #e9eafe;
    margin-top: 15px;
    border-radius: 10px;
  }
}

.SayHelloCustom {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    background: #636ef6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: #e9eafe;
    margin-bottom: 15px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:start {
    background: #e9eafe;
    margin-top: 15px;
    border-radius: 10px;
  }
}
