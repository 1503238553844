.StyledSelectSlider {
  input[type="range"]::-webkit-slider-thumb {
    pointer-events: auto;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    -webkit-appearance: none;
    appearance: none;
    @apply bg-custom-orange shadow-sm;
    cursor: pointer;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"] {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="range"]::-moz-range-thumb {
    pointer-events: auto;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    -webkit-appearance: none;

    @apply bg-custom-orange shadow-sm;
    cursor: pointer;
  }

  input[type="number"] {
    -moz-appearance: none;

    margin: 0;
  }
}
