.MyProfileCompletionGauge {
  .circle {
    width: 156px;
    height: 156px;
    border-top: 10px solid rgba(253, 239, 235, 1);
    border-left: 10px solid rgba(236, 98, 59, 1);
    border-right: 10px solid rgba(253, 239, 235, 1);
    border-bottom: 10px solid rgba(236, 98, 59, 1);
    border-radius: 1000px;
  }
}
