.forumFeedMessage {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
    display: block;
    height: 300px;
  }
  &::-webkit-scrollbar-thumb {
    background: #636ef6;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: #e9eafe;
    margin-bottom: -25px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:start {
    background: #e9eafe;
    margin-top: 10px;
    border-radius: 10px;
  }
}
