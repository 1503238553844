.messageBox {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    margin-bottom: 12px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:start {
    margin-top: 15px;
    border-radius: 10px;
  }

  resize: none;
}
