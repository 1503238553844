.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip p {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  right: -50px;
  bottom: -40px;
  z-index: 1;
}

.tooltip:hover p {
  visibility: visible;
}
