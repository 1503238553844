.CardMemberProfile {
  .bg-gradient {
    // background: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 0%, rgba(47, 47, 47, 0) 45.21%); // keep this line for Pascal
    background: linear-gradient(
      360deg,
      rgb(0 0 0 / 26%) 0%,
      rgba(47, 47, 47, 0) 100%
    );
  }

  .match-button-container {
    opacity: 0;
    transition: opacity 0.1s ease-in;
  }

  &:hover {
    .match-button-container {
      opacity: 1;
    }
  }

  @media (max-width: 600px) {
    .match-button-container {
      opacity: 1 !important;
    }
  }
}
