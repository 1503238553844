.ProfileInterestContainer > div {
  &::-webkit-scrollbar {
    width: 8px;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #636ef6;
    border-radius: 10px;
    position: absolute;
    left: 10px;
  }
  &::-webkit-scrollbar-track-piece:end {
    background: #e9eafe;
    margin-bottom: 25px;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track-piece:start {
    background: #e9eafe;
    margin-top: 12px;
    border-radius: 10px;
  }
}
