.StyledTextArea {
  .text-area {
    &::-webkit-scrollbar {
      width: 8px;
      border-radius: 10px;
      display: block;
    }
    &::-webkit-scrollbar-thumb {
      background: #636ef6;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track-piece:end {
      background: #e9eafe;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track-piece:start {
      background: #e9eafe;
      border-radius: 10px;
    }
  }
}