#user-content {
  p {
    font-size: 18px;
  }

  h2 {
    font-weight: bold;
    font-size: 22px;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 12px;
  }

  img {
    margin: 32px auto;
    border-radius: 1.5rem;

    @media (max-width: 600px) {
      object-fit: cover;
    }
  }
}
